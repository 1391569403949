:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.contact-form {
  position: relative;
  overflow: hidden;
  margin: 0 21px;
  background: #F2F2F2;
  border-radius: 21px;
  display: flex;
  align-items: center; }
  .contact-form .form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    text-align: left;
    column-gap: 128px;
    row-gap: 27px;
    padding: 62px 0; }
    @media (max-width: 992px) {
      .contact-form .form {
        grid-template-columns: 1fr;
        padding: 34px 0;
        row-gap: 15px; } }
    .contact-form .form .label {
      margin-bottom: 18px;
      font-size: 20px;
      color: #0A2057;
      display: block;
      text-transform: uppercase; }
      @media (max-width: 992px) {
        .contact-form .form .label {
          font-size: 12px;
          margin-bottom: 10px; } }
    .contact-form .form .input {
      width: 100%;
      height: 3.5rem;
      margin: 0;
      background: #D9D7D7;
      border: none;
      border-radius: 10px;
      font-size: 1rem; }
      @media (max-width: 992px) {
        .contact-form .form .input {
          height: 30px;
          border-radius: 5px;
          font-size: 14px; } }
    .contact-form .form .textarea {
      width: 100%;
      height: 181px;
      background: #D9D7D7;
      border: none;
      border-radius: 10px;
      font-size: 1rem; }
      @media (max-width: 992px) {
        .contact-form .form .textarea {
          height: 142px; } }
    .contact-form .form .button__container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end; }
    .contact-form .form .button {
      width: 266px;
      height: 74px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #EE2533;
      font-size: 20px;
      color: #EE2533;
      margin-top: 28px;
      margin-left: 1rem;
      text-transform: uppercase;
      background: transparent;
      justify-self: end;
      align-self: end; }
      .contact-form .form .button:hover {
        background-color: #EE2533;
        color: #FFF; }
      @media (max-width: 540px) {
        .contact-form .form .button {
          font-size: 1rem;
          width: 205px;
          height: 57px;
          margin: 3px auto; } }
    .contact-form .form .success-message {
      margin: 1rem 0;
      padding: 1rem;
      border: 2px solid #3ed256;
      background: #d5ffdc;
      text-align: center; }
