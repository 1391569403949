:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.dropdown-component .dropdown__control {
  border: none;
  background: transparent; }
  .dropdown-component .dropdown__control--is-focused {
    box-shadow: none; }

.dropdown-component .dropdown__menu {
  border-radius: 0; }

.dropdown-component .dropdown__indicator-separator {
  display: none; }

.dropdown-component .dropdown__dropdown-indicator {
  background: url("../../../images/arrow.svg");
  background-repeat: no-repeat;
  background-position: center; }
  .dropdown-component .dropdown__dropdown-indicator svg {
    display: none; }

.dropdown-component .dropdown__option--is-focused, .dropdown-component .dropdown__option--is-selected, .dropdown-component .dropdown__option:active {
  background-color: #f6f6f6;
  color: #323232; }
