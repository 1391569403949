:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.cart {
  width: 25rem;
  max-width: 100vw; }
  .cart .overlay__header {
    justify-content: initial; }
    .cart .overlay__header__close-icon {
      margin-left: auto; }
  .cart__list {
    padding: 0 1rem;
    height: calc(100vh - 17.5rem);
    overflow: auto; }
    .cart__list__item {
      padding: 1rem 0;
      border-bottom: 1px solid #f6f6f6;
      display: flex;
      align-content: stretch; }
      .cart__list__item img {
        max-width: 6.25rem;
        height: 6.25rem; }
      .cart__list__item__details {
        width: 100%;
        padding: 0 1rem; }
        .cart__list__item__details p {
          font-weight: 600;
          text-transform: uppercase;
          line-height: 1.5; }
        .cart__list__item__details__variant {
          display: flex;
          justify-content: space-between;
          margin-top: 0.5rem; }
          .cart__list__item__details__variant span {
            color: #c4c4c4;
            font-size: 0.875rem; }
        .cart__list__item__details__delete-icon {
          background-color: #f6f6f6;
          border-radius: 1rem;
          width: 1.5rem;
          height: 1.5rem;
          text-align: center;
          line-height: 1.5rem;
          margin-top: 0.5rem;
          cursor: pointer; }
          .cart__list__item__details__delete-icon svg * {
            transition: 0.3s;
            fill: #c4c4c4; }
          .cart__list__item__details__delete-icon:hover svg * {
            fill: #000; }
  .cart__footer {
    background-color: #f6f6f6;
    padding: 1rem;
    position: absolute;
    bottom: 0;
    width: 25rem; }
    @media (max-width: 540px) {
      .cart__footer {
        width: 100%; } }
    .cart__footer__price {
      display: flex;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      text-transform: uppercase;
      font-weight: 600;
      justify-content: space-between;
      border-bottom: 1px solid #c4c4c4; }
    .cart__footer__button {
      margin: 1.3rem auto 1.3rem;
      text-align: center; }
      .cart__footer__button button {
        width: 85%; }
  .cart__empty {
    padding: 2rem 1rem; }
    .cart__empty h4 {
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 1rem; }
    .cart__empty p {
      color: #c4c4c4; }
    .cart__empty__action {
      text-align: center;
      margin-top: 1rem; }
