:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.account {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  margin-bottom: 2rem; }
  @media screen and (max-width: 540px) {
    .account {
      flex-direction: column; } }
  .account__menu {
    width: 360px;
    margin-right: 2rem; }
  .account__content {
    width: 100%; }
