:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.product-list-item {
  text-align: center;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 50px;
  margin-right: 20px;
  border-color: #D8D8D8;
  border-radius: 10px; }
  .product-list-item__title {
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 1rem;
    text-align: left;
    margin-left: 10px; }
  .product-list-item__undiscounted_price {
    text-decoration: line-through;
    color: #c4c4c4;
    font-size: 0.875rem;
    font-weight: regular; }
  .product-list-item__category {
    color: #c4c4c4;
    text-align: left;
    margin-left: 10px; }
  .product-list-item__price {
    font-weight: 600;
    text-align: right;
    margin-left: 10px;
    padding-right: 10px;
    margin-bottom: 5px; }
  .product-list-item__image {
    background-color: #f6f6f6;
    display: flex;
    height: 255px;
    width: 255px;
    justify-content: center;
    border-radius: 15px; }
    .product-list-item__image img {
      object-fit: contain;
      max-width: 100%;
      border-radius: 10px; }
    @media (max-width: 540px) {
      .product-list-item__image {
        height: 210px; } }
