:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

html, body {
  overflow-x: hidden; }

.home-page__phone_titles {
  display: none; }
  @media (max-width: 540px) {
    .home-page__phone_titles {
      display: block;
      text-align: center;
      width: inherit;
      padding: 16px 4px; } }

.home-page__hero {
  margin: 30px;
  border-radius: 21px;
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  height: 45rem;
  background-image: url("../../../images/layer.png");
  position: relative; }
  .home-page__hero::before {
    content: "";
    position: absolute;
    background-image: linear-gradient(to right, white, #97a4c5, #2c4890);
    border-radius: 21px;
    display: flex;
    width: 100%;
    height: 45rem;
    z-index: -10; }
    @media (max-width: 540px) {
      .home-page__hero::before {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 380px;
        justify-content: space-between;
        padding: 1rem;
        padding-top: 0;
        margin: 0 21px 0px;
        padding-bottom: 21px;
        background-image: linear-gradient(to bottom, white, #97a4c5, #2c4890); } }
  .home-page__hero.layer2 {
    background-image: url("../../../images/layer2.png"); }
  .home-page__hero-text {
    overflow: hidden;
    width: 100%; }
    @media (max-width: 540px) {
      .home-page__hero-text {
        display: none; } }
  @media (max-width: 540px) {
    .home-page__hero {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 380px;
      justify-content: space-between;
      padding: 1rem;
      background-image: url("../../../images/layer_phone.png");
      background-color: transparent;
      padding-top: 0;
      margin: 0 21px 21px; }
      .home-page__hero-text {
        width: 100%;
        max-width: 1181px;
        margin: 0 auto; }
      .home-page__hero-action {
        margin-bottom: 3rem;
        width: 100%; } }
  .home-page__hero__title {
    display: block;
    padding: 1rem 0; }
    @media (max-width: 540px) {
      .home-page__hero__title {
        padding: 0.6rem 0; }
        .home-page__hero__title:last-of-type {
          padding-bottom: 0; } }
    .home-page__hero__title h2 {
      display: inline-block;
      text-transform: uppercase;
      font-weight: bold;
      color: #0A2057; }
      @media (max-width: 540px) {
        .home-page__hero__title h2 {
          font-size: 30px; } }
    .home-page__hero__title h4 {
      display: inline-block;
      color: #EE2533; }
      @media (max-width: 540px) {
        .home-page__hero__title h4 {
          font-size: 1.125rem;
          max-width: 300px;
          line-height: 135%;
          margin-top: 21px; } }

.home-page__categories {
  margin-bottom: 2rem; }
  .home-page__categories h3 {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 1rem; }
  .home-page__categories__list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem; }
    @media (min-width: 540px) {
      .home-page__categories__list {
        grid-template-columns: 1fr 1fr; }
        .home-page__categories__list > div:nth-child(3) {
          grid-column: 3 / 1; }
        .home-page__categories__list > div:nth-child(n + 4) {
          display: none; } }
    .home-page__categories__list a {
      text-decoration: none;
      color: #323232; }
      .home-page__categories__list a h3 {
        text-transform: uppercase;
        font-weight: 600; }
        .home-page__categories__list a h3:after {
          content: url("../../../images/carousel-arrow.svg");
          display: inline-block;
          transform: rotate(180deg);
          margin-left: 1rem; }
    .home-page__categories__list__image {
      width: 100%;
      height: 30rem;
      background-size: cover;
      background-position: center;
      margin-bottom: 1rem; }
      .home-page__categories__list__image--no-photo {
        background-color: #c4c4c4;
        background-repeat: no-repeat;
        background-size: contain; }

.home-page__section-title-container {
  display: flex;
  justify-content: center;
  padding-bottom: 71px;
  padding-top: 108px; }
  @media (max-width: 540px) {
    .home-page__section-title-container {
      padding: 52px 0 56px; } }

.home-page__section-title {
  font-size: 25px;
  color: #0A2057;
  padding-bottom: 11px;
  border-bottom: 3px solid #EE2533;
  text-transform: uppercase;
  font-weight: bold; }
  @media (max-width: 540px) {
    .home-page__section-title {
      font-size: 1rem; } }

.contact-us__image-container {
  position: absolute;
  right: 0;
  bottom: -5px; }
  .contact-us__image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.contact-us__container {
  position: relative;
  overflow: hidden;
  margin: 0 21px;
  background: linear-gradient(90deg, #0a2057 0%, #ef3946 100%);
  border-radius: 21px;
  display: flex;
  align-items: center;
  height: 588px; }
  @media (max-width: 540px) {
    .contact-us__container {
      align-items: flex-start;
      padding-top: 49px;
      justify-content: center;
      text-align: center;
      background: linear-gradient(#0a2057 28.54%, #ef3946 103%);
      height: 559px; } }
  .contact-us__container .btn__outline--red {
    width: 266px;
    height: 74px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #EE2533;
    font-size: 20px;
    color: #EE2533;
    margin-top: 28px;
    text-transform: uppercase; }
    .contact-us__container .btn__outline--red:hover {
      background-color: #EE2533;
      color: #fff; }
    @media (max-width: 540px) {
      .contact-us__container .btn__outline--red {
        font-size: 1rem;
        width: 205px;
        height: 57px;
        margin: 28px auto; } }

.contact-us__text {
  position: relative;
  z-index: 1; }

.contact-us__title {
  display: block;
  padding: 1rem 0; }
  @media (max-width: 540px) {
    .contact-us__title {
      padding: 0.6rem 0; } }
  .contact-us__title h2 {
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff; }
    @media (max-width: 540px) {
      .contact-us__title h2 {
        font-size: 30px; } }
  .contact-us__title h4 {
    display: inline-block;
    color: #fff; }
    @media (max-width: 540px) {
      .contact-us__title h4 {
        font-size: 1.125rem; } }

.home-section {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }
  .home-section h1 {
    padding: 1rem 0; }
  .home-section h4 {
    padding: 2rem 0; }
  .home-section__filters {
    display: flex;
    justify-content: space-around;
    width: 60rem; }
    @media (max-width: 992px) {
      .home-section__filters {
        width: 100%; } }
    .home-section__filters__container {
      width: 15rem;
      border: #c4c4c4;
      border-width: 1px;
      border-style: solid;
      height: 4rem; }
      @media (max-width: 992px) {
        .home-section__filters__container {
          width: 10rem; } }
      @media (max-width: 540px) {
        .home-section__filters__container {
          width: 6rem; } }
  .home-section__products {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    row-gap: 4rem; }
    @media (max-width: 540px) {
      .home-section__products {
        grid-template-columns: 1fr;
        row-gap: 21px; } }
    .home-section__products p {
      padding: 1.5rem 0 0; }
    .home-section__products__image {
      width: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-color: #f1f1f1;
      border-radius: 20px; }
      .home-section__products__image::before {
        content: "";
        display: block;
        padding-top: 54%; }
      .home-section__products__image__title {
        font-family: "DIN Pro";
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        color: #0A2057;
        text-align: start;
        text-transform: uppercase; }
        @media (max-width: 992px) {
          .home-section__products__image__title {
            font-size: 1.5rem; } }
        @media (max-width: 540px) {
          .home-section__products__image__title {
            font-size: 14px; } }

.home-client {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .home-client__header_container {
    padding: 3rem 0; }
  .home-client__container {
    display: flex;
    justify-content: space-around;
    width: 65rem;
    padding-top: 4rem; }
    @media (max-width: 992px) {
      .home-client__container {
        width: 100%; } }
    @media (max-width: 540px) {
      .home-client__container {
        flex-direction: column;
        padding-top: 1rem;
        width: inherit; } }
    .home-client__container__column {
      width: 45%; }
      @media (max-width: 540px) {
        .home-client__container__column {
          width: inherit; } }
      .home-client__container__column__row {
        display: flex;
        justify-content: space-around;
        padding: 0 0 5rem 0; }
        @media (max-width: 992px) {
          .home-client__container__column__row {
            height: 15rem; } }
        @media (max-width: 540px) {
          .home-client__container__column__row {
            width: inherit;
            flex-direction: column;
            padding: 0 0 2rem 0;
            align-items: center;
            height: inherit; } }
        .home-client__container__column__row__image {
          width: 132px;
          height: 132px;
          background-color: black;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%; }
          .home-client__container__column__row__image__lala {
            width: 96px;
            height: 68px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("../../../images/lala.png"); }
          .home-client__container__column__row__image__pemex {
            width: 84px;
            height: 56px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("../../../images/pemex.png"); }
          .home-client__container__column__row__image__home_depot {
            width: 76px;
            height: 76px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("../../../images/home_depot.png"); }
          .home-client__container__column__row__image__waltmart {
            width: 158px;
            height: 106px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("../../../images/waltmart.png"); }
        .home-client__container__column__row__text_wrapper {
          width: 16rem;
          align-items: center;
          justify-content: center;
          display: flex; }
          @media (max-width: 992px) {
            .home-client__container__column__row__text_wrapper {
              width: 11.5rem; } }
          @media (max-width: 540px) {
            .home-client__container__column__row__text_wrapper {
              margin: 1rem 0; } }
          .home-client__container__column__row__text_wrapper p {
            padding: 1rem;
            border: #c4c4c4;
            border-width: 1px;
            border-style: solid;
            border-radius: 8px; }
            @media (max-width: 540px) {
              .home-client__container__column__row__text_wrapper p {
                font-size: 0.8rem; } }

.home-contact {
  margin-top: 80px;
  margin-bottom: 130px; }
  @media (max-width: 540px) {
    .home-contact {
      margin: 52px 0; } }
  .home-contact .wrapper {
    position: relative; }
  .home-contact__title {
    font-size: 3.5rem;
    color: #0A2057;
    line-height: 100%;
    font-weight: bolder;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    margin-bottom: 100px; }
    @media (max-width: 540px) {
      .home-contact__title {
        font-size: 30px;
        max-width: 255px;
        margin: 0 auto 50px;
        line-height: 125%; } }
    .home-contact__title::before {
      content: "";
      width: 137px;
      height: 3px;
      background-color: #EE2533;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -36px; }
      @media (max-width: 540px) {
        .home-contact__title::before {
          display: none; } }
    @media (max-width: 540px) {
      .home-contact__title .br {
        display: none; } }
  .home-contact__container {
    display: flex; }
  .home-contact__image {
    max-width: 100%; }
    .home-contact__image svg {
      max-width: 100%;
      width: 100%; }
  .home-contact .map-message__container {
    position: absolute;
    bottom: 70px;
    left: 32px; }
    @media (max-width: 992px) {
      .home-contact .map-message__container {
        position: initial;
        margin-top: 2rem; } }
    @media (max-width: 540px) {
      .home-contact .map-message__container {
        width: 288px;
        margin: 2rem auto 0; } }
  .home-contact .map-title {
    text-transform: uppercase;
    font-size: 30px;
    margin-bottom: 8px;
    color: #0A2057;
    font-weight: bold; }
    @media (max-width: 540px) {
      .home-contact .map-title {
        font-size: 18px; } }
    .home-contact .map-title__container {
      margin-bottom: 2rem; }
  .home-contact .map-list {
    color: #0A2057;
    font-size: 20px; }
    @media (max-width: 540px) {
      .home-contact .map-list {
        font-size: 1rem; } }
    .home-contact .map-list li {
      margin-bottom: 0.5rem; }
    .home-contact .map-list .map-arrow {
      margin-right: 1rem; }
  .home-contact__header_container {
    text-align: center;
    padding: 6rem 0; }
    @media (max-width: 540px) {
      .home-contact__header_container {
        padding: 0; } }
  .home-contact__wrapper {
    display: flex;
    justify-content: center; }
    @media (max-width: 540px) {
      .home-contact__wrapper {
        flex-direction: column;
        align-items: center; } }
    .home-contact__wrapper__texts {
      width: 28%;
      padding: 2rem 0; }
      @media (max-width: 540px) {
        .home-contact__wrapper__texts {
          width: 70%; } }
      .home-contact__wrapper__texts__explanation {
        font-size: 17px;
        padding: 2rem 0; }
        @media (max-width: 540px) {
          .home-contact__wrapper__texts__explanation {
            font-size: 18px; } }
      .home-contact__wrapper__texts ul {
        font-size: 17px;
        list-style-image: url("../../../images/arrow_forward_black.png");
        padding-left: 1.5rem; }
        @media (max-width: 540px) {
          .home-contact__wrapper__texts ul {
            font-size: 16px; } }
  .home-contact__locations {
    display: grid;
    padding: 15px 50px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 3rem;
    text-align: center; }
    .home-contact__locations h4 {
      text-decoration-line: underline;
      padding-bottom: 10px; }
    @media (max-width: 540px) {
      .home-contact__locations {
        grid-template-columns: 1fr 1fr; } }

.section-title {
  text-align: center;
  padding-bottom: 2rem; }

.header-title {
  font-family: "DIN Pro";
  font-weight: 700;
  font-size: 3.5rem; }
  .header-title.first-part {
    text-align: center; }
  @media (max-width: 992px) {
    .header-title {
      font-size: 2.5rem; } }
  @media (max-width: 540px) {
    .header-title {
      font-size: 30px; } }

.header-subtitle {
  font-family: "DIN Pro";
  font-weight: 400;
  font-size: 1.2rem; }
  @media (max-width: 540px) {
    .header-subtitle {
      font-size: 1rem; } }

.uppercase {
  text-transform: uppercase; }

.strong {
  font-weight: bolder; }

.history-title {
  font-family: "DIN Pro";
  font-weight: 500;
  font-size: 3rem; }

.bottom-line {
  border-block-end-color: #4f4f4f;
  border-block-end-style: solid;
  border-block-end-width: 1px;
  padding-bottom: 3rem; }

.history-subtitle {
  font-family: "DIN Pro";
  font-weight: 500;
  font-size: 0.9rem;
  text-decoration-line: underline; }

.history-text {
  font-family: "DIN Pro";
  font-weight: 500;
  font-size: 1.4rem; }

.router-title {
  font-family: "DIN Pro";
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 25px;
  text-decoration-line: underline;
  text-transform: uppercase; }
  @media (max-width: 540px) {
    .router-title {
      font-size: 0.9rem; } }

.filter-text {
  font-family: "DIN Pro";
  font-weight: 500;
  font-size: 0.8rem; }

.product-title {
  font-family: "DIN Pro";
  font-weight: 700;
  font-size: 1rem; }
  @media (max-width: 992px) {
    .product-title {
      font-size: 0.7rem; } }
  @media (max-width: 540px) {
    .product-title {
      font-size: 0.8rem; } }
