:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.us-container {
  background-color: #e1e4ea;
  overflow-x: hidden; }

.us-layout {
  display: grid;
  column-gap: 100px;
  row-gap: 54px;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 125px; }
  @media (max-width: 992px) {
    .us-layout {
      grid-template-columns: 1fr;
      justify-items: center;
      padding-bottom: 56px; } }
  .us-layout .us-history {
    max-width: 574px;
    background-color: #fff;
    padding: 52px 0;
    border-radius: 35px; }
    @media (max-width: 992px) {
      .us-layout .us-history {
        padding: 0;
        border-radius: 25px; } }
    @media (max-width: 992px) {
      .us-layout .us-history-container {
        order: 2;
        display: grid;
        justify-items: center; } }
    .us-layout .us-history-container .title-mobile {
      display: none;
      text-align: center;
      max-width: max-content;
      margin-bottom: 35px; }
      @media (max-width: 992px) {
        .us-layout .us-history-container .title-mobile {
          display: block; } }
    .us-layout .us-history .history {
      display: flex;
      align-items: flex-start; }
  .us-layout .us-company {
    color: #0A2057;
    display: flex;
    flex-direction: column;
    gap: 40px; }
    .us-layout .us-company-item {
      display: flex;
      gap: 18px; }
      @media (max-width: 540px) {
        .us-layout .us-company-item {
          max-width: 315px; } }
      .us-layout .us-company-item p {
        font-size: 17px;
        margin-bottom: 32px; }
        .us-layout .us-company-item p:last-of-type {
          margin-bottom: 0; }
      .us-layout .us-company-item strong {
        font-weight: bold; }
    .us-layout .us-company-arrow {
      width: 15px; }
    .us-layout .us-company-title {
      font-size: 35px;
      font-weight: bold;
      border-bottom: 3px solid #0A2057;
      padding-bottom: 8px;
      width: min-content;
      margin-bottom: 2rem; }
      @media (max-width: 992px) {
        .us-layout .us-company-title {
          font-size: 27px;
          border-bottom: 2px solid #0A2057; } }
    .us-layout .us-company-hr {
      height: 0.5px;
      background-color: #0a2057; }
      @media (max-width: 540px) {
        .us-layout .us-company-hr {
          margin-left: 33px; } }

.timeline {
  position: relative;
  width: 100px;
  padding-right: 20px;
  display: flex;
  justify-content: center;
  flex-shrink: 0; }
  @media (max-width: 992px) {
    .timeline {
      display: none; } }
  .timeline-mobile {
    width: 100%;
    padding: 28px 0 76px;
    display: none; }
    @media (max-width: 992px) {
      .timeline-mobile {
        display: block; } }
    .timeline-mobile .line {
      width: 100%;
      height: 2px;
      background-color: #0A2057; }
  .timeline .story-points {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly; }
    .timeline .story-points-mobile {
      flex-direction: row; }
    .timeline .story-points .history-point {
      color: #fff;
      width: 72px;
      height: 72px;
      margin-left: 4px;
      border: 3px solid white;
      font-size: 1rem; }
      @media (max-width: 992px) {
        .timeline .story-points .history-point {
          font-size: 14px;
          width: 56px;
          height: 56px; } }
      .timeline .story-points .history-point.year_1998 {
        background-color: #ee2533; }
      .timeline .story-points .history-point.year_2005 {
        background-color: #a2233f; }
      .timeline .story-points .history-point.year_2010 {
        background-color: #7c2345; }
      .timeline .story-points .history-point.year_2015 {
        background-color: #302151; }
      .timeline .story-points .history-point.year_2022 {
        background-color: #0a2057; }

.history-content {
  padding-right: 100px;
  width: 100%; }
  @media (max-width: 992px) {
    .history-content {
      padding: 42px 48px;
      display: flex;
      flex-direction: column;
      justify-content: center; } }
  .history-content .title {
    text-align: center;
    font-size: 2rem;
    text-transform: uppercase;
    color: #0A2057;
    font-weight: bold;
    margin-top: 24px;
    margin-bottom: 30px; }
    @media (max-width: 992px) {
      .history-content .title {
        display: none; } }
  .history-content .history-content-inner {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .history-content .history-image {
    margin-bottom: 54px; }
    @media (max-width: 992px) {
      .history-content .history-image {
        width: 100%; } }
  .history-content__subtitle {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    color: #EE2533;
    border-bottom: 2px solid #EE2533;
    width: min-content;
    margin-bottom: 32px; }
    @media (max-width: 540px) {
      .history-content__subtitle {
        font-size: 14px;
        margin-bottom: 24px; } }
  .history-content__description {
    color: #0A2057;
    font-size: 18px; }
    @media (max-width: 540px) {
      .history-content__description {
        font-size: 14px; } }
    .history-content__description p {
      margin-bottom: 32px; }
      @media (max-width: 540px) {
        .history-content__description p {
          margin-bottom: 24px; } }
      .history-content__description p:last-of-type {
        margin-bottom: 0; }
    .history-content__description strong {
      font-weight: bold; }
