:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.product-list-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 2rem;
  margin-bottom: 42px; }
  @media (max-width: 992px) {
    .product-list-container {
      grid-template-columns: 1fr 1fr 1fr; } }
  @media (max-width: 540px) {
    .product-list-container {
      grid-template-columns: 1fr 1fr; } }
