:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.page__title {
  font-size: 25px;
  color: #0A2057;
  text-transform: uppercase;
  padding-bottom: 11px;
  border-bottom: 3px solid #EE2533;
  font-weight: bold; }
  @media (max-width: 540px) {
    .page__title {
      font-size: 1rem; } }

.page__title__container {
  display: flex;
  justify-content: center;
  margin: 45px 0 49px; }

.category-page__categories {
  display: flex;
  width: 100%;
  gap: 32px;
  margin: 80px 0 50px; }
  @media (max-width: 992px) {
    .category-page__categories {
      margin: 60px 0 40px; } }
  @media (max-width: 540px) {
    .category-page__categories {
      gap: 24px; } }
  .category-page__categories__wrapper {
    width: 170px; }
  .category-page__categories__imageContainer {
    aspect-ratio: 16 / 9;
    background-color: #E2E4EB;
    border-radius: 10px;
    overflow: hidden; }
    .category-page__categories__imageContainer img {
      object-fit: cover;
      width: 100%;
      height: 100%; }
  .category-page__categories__title {
    font-size: 13px;
    font-weight: bold;
    color: #0A2057;
    text-transform: uppercase;
    text-align: center;
    line-height: 120%;
    margin-top: 1.5rem; }

@media (max-width: 992px) {
  .categories--large {
    display: none; } }

@media (max-width: 540px) {
  .categories--medium {
    display: none; } }

@media (min-width: 992px) {
  .categories--medium {
    display: none; } }

.categories--medium.swiper {
  padding: 0 50px; }

@media (min-width: 540px) {
  .categories--small {
    display: none; } }

.categories--small.swiper {
  padding: 0 50px; }

.categories_list {
  position: fixed;
  z-index: 100;
  background-color: white;
  margin: 0 auto;
  width: 100%; }

.category__wrapper__products {
  margin-top: 283px; }

@media (max-width: 540px) {
  .categories_list {
    top: 100px; }
  .category__wrapper__products {
    margin-top: 150px; } }

.page__title_hide {
  display: none; }

@keyframes categories {
  0% {
    margin-top: 40px; }
  100% {
    margin-top: 20px; } }

.wrapper_margintop {
  animation: categories ease 0.5s;
  transition: all;
  margin-top: 20px; }

.menu-product {
  padding-bottom: 1rem; }
  .menu-product .item-menu {
    text-transform: uppercase;
    display: inline-block;
    width: 90%;
    font-weight: 400; }

.breadcrumbs {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 1rem 0; }
  @media (max-width: 540px) {
    .breadcrumbs {
      font-size: 12px;
      display: flex;
      justify-content: center; } }
  .breadcrumbs li:not(:last-of-type):after {
    content: url("../../../images/breadcrumbs-arrow.svg");
    display: inline-block;
    margin: 0 0.5rem; }
  .breadcrumbs li a {
    color: #7d7d7d;
    text-decoration: none;
    vertical-align: middle;
    transition: 0.3s; }
    .breadcrumbs li a:hover, .breadcrumbs li a:focus {
      color: #000; }
  .breadcrumbs__active a {
    color: #323232;
    font-weight: 600; }
    .breadcrumbs__active a:hover {
      color: #323232; }
