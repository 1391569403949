:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.window {
  position: relative;
  margin: auto;
  margin-top: 50px;
  border-radius: 25px;
  font-family: "Roboto";
  font-style: normal;
  color: black;
  height: 160px; }
  @media (max-width: 480px) {
    .window {
      width: 100%; } }

.modalMessage__header {
  position: relative;
  display: flex;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 2rem;
  height: 80px;
  background: #0a2057;
  background: linear-gradient(90deg, #0a2057 0%, #ec3946 70%);
  border-top-left-radius: 25px;
  border-top-right-radius: 25px; }
  @media (max-width: 540px) {
    .modalMessage__header {
      display: block;
      text-align: center;
      height: 100%;
      padding-bottom: 100px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px; } }

.modalMessage__icon {
  position: absolute;
  right: 43px;
  margin-top: 10px; }
  @media (max-width: 540px) {
    .modalMessage__icon {
      margin: auto;
      right: 0px;
      left: 0px;
      margin-top: 10px;
      width: 60px;
      height: 60px; } }

.modalMessage__content {
  z-index: 1;
  padding-top: 10px;
  background-color: #ffffff;
  padding-left: 30px;
  padding-bottom: 40px;
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
  position: relative; }
  @media (max-width: 540px) {
    .modalMessage__content {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      padding-left: 10px; } }

.modalMessage .modalMessage__header p {
  padding-top: 17px;
  font-weight: 600;
  font-size: 26px;
  line-height: 26px;
  color: #ffffff; }
  @media (max-width: 540px) {
    .modalMessage .modalMessage__header p {
      font-size: 36px;
      line-height: 46px;
      width: 30%;
      margin: auto; } }

.modalMessage__image {
  position: absolute;
  right: 3px;
  top: 13.36%;
  z-index: -1; }
  @media (max-width: 540px) {
    .modalMessage__image {
      right: 0px; } }

.modalMessage__foot {
  margin-top: 2rem;
  position: relative;
  font-weight: 100;
  font-size: 13px;
  line-height: 26px;
  display: flex; }
  @media (max-width: 540px) {
    .modalMessage__foot {
      text-align: center;
      align-items: center;
      display: block;
      text-align: center; } }

.modalMessage__returnBtn {
  width: 130px;
  height: 50px;
  background: #ffffff;
  border: 2px solid #ec3946;
  box-sizing: border-box;
  border-radius: 45px;
  color: #ec3946;
  text-decoration: none;
  font-size: 15px;
  margin-left: 25px;
  margin-right: 25px; }
  @media (max-width: 540px) {
    .modalMessage__returnBtn {
      width: 180px;
      height: 60px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 2px; } }

@media (max-width: 540px) {
  .modalMessage__content p {
    text-align: left;
    width: 50%;
    margin: 0 auto;
    line-height: 20px; } }

@media (max-width: 540px) {
  .modalMessage__foot p {
    text-align: center;
    width: 40%;
    margin: 0 auto;
    line-height: 20px; } }
