:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.select {
  position: relative;
  margin-bottom: 1rem; }
  .select__title {
    display: block;
    position: relative;
    cursor: pointer;
    width: 100%;
    border: 1px solid #c4c4c4; }
    .select__title input {
      display: block;
      width: 100%;
      padding: 0.8rem 1rem;
      font-size: 1rem;
      outline: none;
      border: none;
      background: transparent; }
      .select__title input:active, .select__title input:focus, .select__title input::selection {
        background: transparent; }
    .select__title:after {
      position: absolute;
      display: block;
      content: "";
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      width: 0.5rem;
      height: 0.5rem;
      transition: all 0.3s ease;
      background: url("../../../images/arrow.svg");
      background-repeat: no-repeat;
      background-position: center; }
  .select__container {
    position: relative; }
  .select__options {
    border-radius: 0;
    box-shadow: 0 0 0 1px #c4c4c4;
    border: 1px solid #c4c4c4;
    background-color: #fff;
    position: absolute;
    left: 0;
    bottom: 0.1rem;
    transform: translateY(100%);
    width: 100%;
    min-width: 80px;
    min-height: 43px;
    max-height: 40vh;
    opacity: 0;
    z-index: -1;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.15);
    overflow: auto; }
    .select__options--open {
      opacity: 1;
      z-index: 2; }
  .select__option {
    position: relative;
    padding: 1rem 1.5rem; }
    .select__option:not(:last-child)::after {
      background-color: #c4c4c4;
      bottom: 0;
      content: "";
      height: 1px;
      left: 1rem;
      position: absolute;
      width: calc(100% - 2rem); }
    .select__option--disabled {
      color: #7d7d7d; }
    .select__option:hover {
      cursor: pointer;
      background-color: rgba(33, 18, 94, 0.1); }
    .select__option--selected, .select__option:focus, .select__option:active {
      background-color: #f6f6f6;
      color: #323232; }
  .select__hidden {
    position: absolute;
    pointer-events: none;
    opacity: 0;
    height: 0;
    width: 0; }
