:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.price-filter {
  position: relative; }
  .price-filter__dropdown {
    display: none;
    position: absolute;
    background-color: #fff;
    border: 1px solid #c4c4c4;
    box-shadow: 0 0 0 1px #c4c4c4;
    padding: 1rem;
    width: 20rem;
    justify-content: space-between;
    z-index: 2; }
    .price-filter__dropdown--visible {
      display: flex; }
    .price-filter__dropdown .input {
      width: 8rem; }
