@import url("~reset-css");
:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.u-lead {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 10px; }
  .u-lead--bold {
    color: #7d7d7d;
    font-weight: 900; }

.u-uppercase {
  text-transform: uppercase; }

.u-link {
  color: #c4c4c4;
  text-decoration: underline;
  cursor: pointer; }

.c-option {
  cursor: pointer;
  padding: 1rem 1rem 1rem 2.5rem;
  position: relative; }
  .c-option label {
    cursor: pointer; }
    .c-option label::before {
      display: block;
      position: absolute;
      content: "";
      border-radius: 100%;
      height: 0.5rem;
      width: 0.5rem;
      top: 1.25rem;
      left: 1rem;
      margin: auto;
      box-shadow: 0 0 0 1px #c4c4c4;
      border: 2px solid #fff;
      background-color: #fff; }
  .c-option--selected label::before {
    background-color: #c4c4c4; }
  .c-option--disabled label::before {
    background-color: rgba(196, 196, 196, 0.5);
    box-shadow: 0 0 0 1px #c4c4c4; }
  .c-option input {
    display: none; }

body {
  font-family: "DIN Pro", sans-serif;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #323232; }
  body * {
    box-sizing: border-box; }
  body strong {
    font-weight: 700; }
  body h1 {
    font-size: 4rem;
    line-height: 1; }
  body h3 {
    font-size: 1.5rem;
    line-height: 1.7rem; }
  body h4 {
    font-size: 1.125rem; }
  body a {
    text-decoration: none;
    font-weight: normal; }
  body p {
    line-height: 1.5rem; }
  body #root {
    overflow-x: hidden;
    display: flex;
    min-height: 100vh;
    flex-direction: column; }
    body #root > div:first-of-type {
      flex: 1; }
  body .container {
    width: 1140px;
    max-width: 100vw;
    margin: 0 auto;
    padding: 0 1rem; }
    @media (max-width: 992px) {
      body .container {
        width: 100%; } }
  body table {
    width: 100%;
    margin: 3rem 0;
    font-size: 0.875rem; }
    body table th {
      color: #7d7d7d;
      text-align: left;
      padding: 1rem 0.5rem; }
    body table tr {
      color: #323232;
      border-bottom: 1px solid #f6f6f6; }
    body table td {
      padding: 1rem 0.5rem;
      vertical-align: middle; }
      body table td img {
        vertical-align: middle;
        margin-right: 1rem; }
  body .checkbox {
    position: relative;
    display: inline; }
    body .checkbox:before {
      content: "";
      display: block;
      position: absolute;
      left: 0.4rem;
      top: 0.05rem;
      width: 0.3rem;
      height: 0.6rem;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      z-index: 1; }
    body .checkbox input {
      display: none; }
      body .checkbox input:checked ~ span:before {
        background-color: #c4c4c4; }
    body .checkbox span {
      margin-left: 2rem; }
      body .checkbox span:before {
        background-color: #fff;
        border-radius: 0.2rem;
        border: 1px solid #c4c4c4;
        content: "";
        display: block;
        height: 1rem;
        position: absolute;
        top: 0;
        width: 1rem; }

.form-error {
  color: #fe6e76; }

/* NAVBAR STYLES */
.sidenav {
  height: 100%;
  width: 280px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px; }

.sidenav a {
  padding: 8px 20px 8px 20px;
  text-decoration: none;
  font-size: 18px;
  display: block;
  margin-bottom: 35px; }

#sideMenuLogoContainer {
  text-align: center; }

.dropdown-container {
  margin-left: 10px;
  padding-bottom: 1rem;
  margin-top: 10px; }

.submenu-item {
  color: #bdbdbd;
  font-family: "DIN Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 17px; }

.submenu-selected {
  color: black; }

.arrow-submenu {
  width: 12px;
  height: 10px;
  padding-right: 20px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../../images/arrow_forward_gray.png"); }
  .arrow-submenu.arrow-submenu-selected {
    background-image: url("../../images/arrow_forward_black.png"); }

.sidenav a:hover {
  color: #58fad0; }

.dropdown-btn {
  text-align: right;
  font-size: 25px;
  cursor: pointer; }

.title-dropdown {
  font-family: "DIN Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 20px; }
  @media (max-width: 992px) {
    .title-dropdown {
      font-size: 15px; } }

#sideMenuLogo {
  width: 60%;
  height: auto;
  margin-top: 30px;
  margin-bottom: 40px; }

.sidenav .closebtn {
  position: absolute;
  right: 10px;
  font-size: 36px;
  margin-left: 50px; }

.closebtn {
  top: 30px; }

.wrapper {
  width: 100%;
  max-width: 1222px;
  padding: 0 21px;
  margin: 0 auto; }

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px; }
  .sidenav a {
    font-size: 18px; } }

/* END NAVBAR STYLE */
@font-face {
  font-family: "DIN pro";
  src: local("DINPro-Regular"), url("../fonts/DINPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "DIN pro";
  src: local("DINPro-Regular"), url("../fonts/DINPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "DIN pro";
  src: local("DINPro-Regular"), url("../fonts/DINPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }
