:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.product-filters {
  background-color: #f6f6f6; }
  .product-filters__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
    padding: 1rem 0; }
    @media (max-width: 992px) {
      .product-filters__grid {
        grid-template-columns: repeat(2, 1fr); } }
    @media (max-width: 540px) {
      .product-filters__grid {
        grid-template-columns: 1fr; } }
