:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.footer__favicons {
  border-top: 1px solid #f6f6f6;
  display: flex;
  justify-content: center; }

.footer__menu {
  background-color: #f6f6f6;
  padding: 3rem 0 2rem; }
  .footer__menu .container {
    display: flex;
    flex-wrap: wrap; }
    .footer__menu .container div:not(:last-of-type) {
      padding-right: 3rem; }
  .footer__menu-section-header {
    font-weight: 600;
    margin-bottom: 1rem; }
  .footer__menu-section-content p {
    font-weight: 600;
    margin-bottom: 1rem;
    font-size: 0.875rem;
    color: #c4c4c4; }
    .footer__menu-section-content p a {
      text-decoration: none;
      color: #c4c4c4; }

.footer-nav {
  height: inherit;
  background-color: #0A2057;
  color: white;
  padding: 80px 0 37px;
  display: flex;
  flex-direction: column;
  justify-content: space-around; }
  .footer-nav__container {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    margin-bottom: 100px; }
    @media (max-width: 992px) {
      .footer-nav__container {
        grid-template-columns: 150px 1fr;
        column-gap: 60px;
        row-gap: 24px;
        margin-bottom: 32px; } }
    @media (max-width: 540px) {
      .footer-nav__container {
        grid-template-columns: 80px 1fr;
        column-gap: 60px; } }
  .footer-nav__containerList {
    display: flex;
    gap: 90px;
    justify-content: center;
    padding-top: 30px; }
    @media (max-width: 992px) {
      .footer-nav__containerList {
        flex-direction: column;
        gap: 32px;
        padding-top: 0;
        justify-self: center; } }
  .footer-nav__logo {
    width: 150px; }
    @media (max-width: 992px) {
      .footer-nav__logo {
        justify-self: center;
        width: 100%; } }
    .footer-nav__logo svg * {
      fill: white; }
  .footer-nav__title {
    color: #EE2533;
    font-size: 1rem;
    text-transform: uppercase;
    margin-bottom: 26px; }
    @media (max-width: 540px) {
      .footer-nav__title {
        font-size: 12px;
        margin-bottom: 12px; } }
  .footer-nav .contact-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-weight: bold; }
    @media (max-width: 540px) {
      .footer-nav .contact-list {
        font-size: 12px;
        gap: 12px; } }
    .footer-nav .contact-list span {
      color: #EE2533; }
  @media (max-width: 540px) {
    .footer-nav .list {
      font-size: 12px; } }
  .footer-nav .list li {
    margin-bottom: 10px; }
  .footer-nav__locale {
    justify-self: end;
    padding-top: 30px; }
    @media (max-width: 992px) {
      .footer-nav__locale {
        padding-top: 0; } }
    .footer-nav__locale .arrow-down {
      margin-left: 14px; }
    .footer-nav__locale .dropdown {
      color: #0A2057;
      width: 80px; }
    .footer-nav__locale label {
      cursor: pointer; }
      .footer-nav__locale label:hover {
        border-bottom: 2px solid #F05C66; }
  .footer-nav .footer__socialMedia .socialMedia {
    display: none;
    justify-content: center;
    align-items: center;
    gap: 42px;
    margin-bottom: 37px; }
    @media (max-width: 992px) {
      .footer-nav .footer__socialMedia .socialMedia {
        display: flex; } }
  .footer-nav .information-pages {
    align-self: center;
    color: #FFF;
    font-family: "DIN Pro";
    font-weight: 500;
    font-size: .8rem; }
    .footer-nav .information-pages .horizontal-rule {
      border-top: 1px solid #EE2533;
      width: 100%;
      margin-bottom: 30px; }
    @media (max-width: 540px) {
      .footer-nav .information-pages {
        flex-direction: column;
        align-items: center; } }
    .footer-nav .information-pages .rights {
      display: flex;
      justify-content: space-between; }
      @media (max-width: 540px) {
        .footer-nav .information-pages .rights {
          width: inherit;
          padding-left: 0rem;
          text-align: center;
          flex-direction: column;
          gap: 1rem; } }
      .footer-nav .information-pages .rights .terms {
        display: flex;
        gap: 32px; }
        .footer-nav .information-pages .rights .terms a {
          color: #FFF; }
        @media (max-width: 540px) {
          .footer-nav .information-pages .rights .terms {
            flex-direction: column; } }
    .footer-nav .information-pages .pages {
      width: 50%;
      display: flex;
      justify-content: flex-end; }
      .footer-nav .information-pages .pages p {
        padding-right: 2rem; }
        .footer-nav .information-pages .pages p a {
          color: #585858; }
      @media (max-width: 540px) {
        .footer-nav .information-pages .pages {
          padding: 2rem 0rem;
          width: 100%;
          text-align: center; } }
  .footer-nav .container {
    display: flex;
    flex-wrap: wrap; }
    .footer-nav .container div:not(:last-of-type) {
      padding-right: 3rem; }
  .footer-nav__section-header {
    font-weight: 600;
    margin-bottom: 1rem;
    text-transform: uppercase; }
  .footer-nav__section-content p {
    margin-bottom: 1rem;
    font-size: 0.875rem;
    color: #c4c4c4; }
    .footer-nav__section-content p a {
      text-decoration: none;
      color: #c4c4c4; }
