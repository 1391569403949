:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.product-description h3 {
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 1rem; }

.product-description h4 {
  font-weight: 600;
  margin-bottom: 1rem; }

.product-description__rich-text p {
  min-height: 24px;
  width: 100%; }

.product-description__variant--picker {
  display: flex;
  flex-direction: column;
  width: 100%; }

.product-description__variant--select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px; }
  .product-description__variant--select p {
    width: 50%; }

.product-description__about {
  margin: 2rem 0; }
  .product-description__about h4 {
    font-weight: 600;
    color: #c4c4c4;
    border-bottom: 1px solid #c4c4c4;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem; }
  .product-description__about p {
    font-size: 0.875rem; }
  .product-description__about img {
    max-width: 100%; }

.product-description__undiscounted_price {
  text-decoration: line-through;
  color: #c4c4c4;
  font-size: 0.875rem;
  font-weight: regular; }

.product-description button.description_action {
  max-width: 216px;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #0a2057;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  background-color: #0a2057;
  margin-left: 16px; }
  .product-description button.description_action:hover {
    background-color: #fff;
    color: #0A2057; }
  @media (max-width: 540px) {
    .product-description button.description_action {
      font-size: 1rem;
      width: 205px;
      height: 57px;
      margin: 16px auto; } }
  .product-description button.description_action:disabled {
    background-color: #dadada;
    border: 2px solid #dadada;
    color: #585858; }

.product-description__selected-attributes {
  font-size: 0.875rem;
  margin-top: 20px; }
  .product-description__selected-attributes > span:first-child {
    color: #7d7d7d; }

.product-description__variant-picker {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px; }
  .product-description__variant-picker .react-select-wrapper,
  .product-description__variant-picker .input {
    width: 50%;
    margin-bottom: 1rem; }
  .product-description__variant-picker__quantity {
    display: flex; }
    .product-description__variant-picker__quantity .input {
      margin-left: 2rem;
      max-width: 6rem; }
      .product-description__variant-picker__quantity .input .input__field {
        max-width: 100%; }

.product-description__error-message {
  color: #fe6e76; }

.product-description__quantity-input {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 540px) {
    .product-description__quantity-input {
      width: 100%;
      margin-top: 1rem; } }
  @media (max-width: 800px) {
    .product-description__quantity-input {
      justify-content: center; } }

.product-description__quantity_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0; }

.product-description__price_container {
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin: 1rem 0;
  margin-bottom: 1rem;
  padding-top: 1rem; }
  @media (max-width: 540px) {
    .product-description__price_container {
      flex-direction: column; } }
  @media (max-width: 540px) {
    .product-description__price_container__cart {
      width: 100%;
      display: flex;
      justify-content: center; } }

.wrapper-quantity {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 2rem;
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */ }
  .wrapper-quantity input::-webkit-outer-spin-button,
  .wrapper-quantity input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  @media (max-width: 540px) {
    .wrapper-quantity {
      justify-content: center; } }
  .wrapper-quantity input[type="number"] {
    -moz-appearance: textfield; }
  .wrapper-quantity__add {
    font-family: sans-serif;
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid #323232;
    text-align: center;
    border-radius: 50px;
    align-items: center;
    display: flex;
    margin-right: 0.8rem;
    justify-content: center;
    font-size: 1.8rem;
    background-color: #fff;
    color: #0A2057; }
    .wrapper-quantity__add:hover {
      background-color: #0A2057;
      color: #fff; }
  .wrapper-quantity__substract {
    font-family: sans-serif;
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid #323232;
    text-align: center;
    border-radius: 50px;
    align-items: center;
    display: flex;
    margin-left: 0.8rem;
    justify-content: center;
    font-size: 1.8rem;
    background-color: #fff;
    color: #0A2057; }
    .wrapper-quantity__substract:hover {
      background-color: #0A2057;
      color: #fff; }

.product-title {
  font-family: "DIN Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 48px; }

.quantity-text-field {
  display: flex;
  align-items: center; }
  .quantity-text-field .input-quantity {
    padding: 0;
    height: 77px;
    width: 81px;
    text-align: center; }

.color__picker {
  display: flex;
  align-items: center;
  margin: 1rem 0; }
  .color__picker--label {
    width: 50%; }
  .color__picker--col {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 50%; }
  .color__picker .circle {
    width: 32px;
    height: 32px;
    background-color: red;
    border-radius: 50%;
    border: 1px solid black;
    cursor: pointer; }
    .color__picker .circle__container {
      margin: 0 0.2rem 0.2rem 0;
      padding: 4px; }
    .color__picker .circle--active {
      background-color: #e0e0e0;
      border-radius: 50%; }

.button__download {
  text-align: center;
  border-radius: 50px;
  align-items: center;
  display: flex;
  margin-left: 0.8rem;
  justify-content: center;
  font-size: 1.8rem;
  color: #fff;
  background-color: #0A2057;
  border: 2px solid #0A2057; }
  .button__download:hover {
    background-color: #fff;
    color: #0A2057; }

.modal__quote__message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 497px; }
  .modal__quote__message h1 {
    color: #0A2057;
    margin-bottom: 24px;
    font-size: 30px;
    text-align: center;
    line-height: 2rem; }
  .modal__quote__message button {
    margin-top: 24px;
    border: 1px solid #0A2057;
    background-color: #0A2057;
    color: #fff;
    width: 176px;
    height: 48px;
    margin-bottom: 16px;
    text-transform: uppercase;
    transition: background-color 0.2s ease; }
    .modal__quote__message button:hover {
      color: #0A2057;
      background-color: #fff; }

.modal__quote__form {
  display: flex;
  width: 100%;
  max-width: 640px; }
  @media (max-width: 540px) {
    .modal__quote__form {
      flex-direction: column; } }
  .modal__quote__form--col {
    width: 50%;
    padding-right: 16px; }
    @media (max-width: 540px) {
      .modal__quote__form--col {
        width: 100%;
        padding-right: 0; } }
  .modal__quote__form--pr {
    margin-right: 40px; }

.modal__quote__input {
  display: flex;
  flex-direction: column;
  padding-bottom: 16px; }
  .modal__quote__input label {
    color: #0A2057;
    margin-bottom: 8px;
    text-transform: uppercase; }
  .modal__quote__input input,
  .modal__quote__input textarea {
    border: none;
    background-color: #d9d7d7;
    min-height: 32px;
    padding: 8px;
    border-radius: 8px;
    color: #323232; }
  .modal__quote__input input:hover {
    color: #323232; }
  .modal__quote__input textarea {
    height: 136px;
    resize: none; }

.modal__quote__button {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  height: 180px; }
  .modal__quote__button button {
    border: 1px solid #ee2735;
    color: #ee2735;
    background-color: #fff;
    width: 176px;
    height: 48px;
    margin-bottom: 16px;
    text-transform: uppercase;
    transition: background-color 0.2s ease; }
    .modal__quote__button button:hover {
      background-color: #ee2735;
      color: #fff; }
    .modal__quote__button button:disabled {
      background-color: #d9d7d7;
      border: 1px solid #585858;
      color: #585858;
      cursor: not-allowed; }
