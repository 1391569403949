:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.whatsapp__container {
  position: fixed;
  bottom: 25px;
  right: 52px;
  z-index: 1;
  border-radius: 50%; }
  @media (max-width: 540px) {
    .whatsapp__container {
      bottom: 15px;
      right: 30px; } }

.whatsapp__img {
  width: 77px;
  height: 77px; }
