:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.password-reset-form .input {
  margin: 1rem 0 2rem; }

.password-reset-form__button {
  text-align: center;
  margin: 2rem 0 2rem 2rem; }
