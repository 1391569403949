:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

button.button {
  background-color: #000;
  padding: 0.9rem 3.7rem;
  border: none;
  transition: 0.3s;
  outline: none;
  font-family: "DIN Pro", sans-serif;
  border-radius: 50px;
  cursor: pointer; }
  @media (max-width: 540px) {
    button.button {
      padding: 0.9rem 1rem;
      width: 88%;
      max-width: 88%; } }
  button.button > span {
    display: inline-block;
    color: #fff;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.25rem;
    font-family: "DIN Pro"; }
  button.button:hover {
    background-color: #7d7d7d; }
  button.button:active {
    box-shadow: -3px 3px 14px 0px rgba(129, 67, 67, 0.2); }
  button.button.secondary {
    background-color: #fff;
    box-shadow: inset 0px 0px 0px 3px #c4c4c4;
    border-left: 1px solid #c4c4c4;
    border-right: 1px solid #c4c4c4; }
    button.button.secondary * {
      color: #000; }
    button.button.secondary:hover {
      background-color: #7d7d7d; }
      button.button.secondary:hover * {
        color: #fff; }
    button.button.secondary:active {
      background-color: #7d7d7d; }
  button.button:disabled {
    background-color: #c4c4c4; }
    button.button:disabled, button.button:disabled:hover {
      cursor: default; }
