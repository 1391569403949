:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.product-page {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  color: #0A2057; }
  .product-page__video_container {
    display: flex;
    justify-content: center;
    align-items: center; }
  .product-page__categories {
    display: flex;
    width: 80%; }
    .product-page__categories__see_more {
      display: flex;
      align-items: center;
      padding-right: 3rem; }
      @media (max-width: 992px) {
        .product-page__categories__see_more {
          width: 20%; } }
      .product-page__categories__see_more__title {
        font-family: "DIN Pro";
        font-weight: bold;
        font-size: 14px;
        text-align: center; }
    .product-page__categories__next-image {
      display: flex;
      justify-content: center;
      font-size: 2rem; }
    .product-page__categories__wrapper {
      height: 150px;
      width: 150px;
      display: none;
      justify-content: center;
      align-items: center;
      border-right: solid;
      border-right-width: thin;
      border-right-color: #f6f6f6; }
      .product-page__categories__wrapper a {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column; }
      @media (max-width: 992px) {
        .product-page__categories__wrapper {
          height: 120px;
          width: 120px; } }
      .product-page__categories__wrapper__title {
        font-family: "DIN Pro";
        text-align: center;
        font-weight: bold;
        font-size: 11px;
        text-transform: uppercase;
        padding-top: 10px; }
      .product-page__categories__wrapper__image {
        width: 90px;
        height: 90px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        padding: 0 15px 0 15px; }
  .product-page__product_wrapper {
    display: flex; }
    @media (max-width: 800px) {
      .product-page__product_wrapper {
        width: 100%;
        flex-direction: column-reverse; } }
    .product-page__product_wrapper__description {
      padding-right: 132px; }
      @media (max-width: 992px) {
        .product-page__product_wrapper__description {
          padding-right: 32px; } }
      @media (max-width: 540px) {
        .product-page__product_wrapper__description {
          padding: 0; } }
  .product-page .breadcrumbs {
    background-color: #fff;
    margin-top: 2rem; }
  .product-page__product {
    display: flex;
    margin-top: 1rem;
    padding-bottom: 5rem; }
    @media (max-width: 992px) {
      .product-page__product {
        justify-content: center; } }
    @media (max-width: 540px) {
      .product-page__product {
        display: block;
        padding-bottom: 1rem; } }
    .product-page__product__description {
      width: 100%;
      min-height: 10rem;
      margin-bottom: 9rem;
      max-width: 560px; }
      @media (max-width: 540px) {
        .product-page__product__description {
          display: block;
          margin-bottom: 1rem;
          padding: 6rem 0 3rem; } }
      .product-page__product__description .category-title {
        font-family: "DIN Pro";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        padding: 1.5rem 0;
        text-transform: uppercase; }
        @media (max-width: 800px) {
          .product-page__product__description .category-title {
            display: none; } }
      .product-page__product__description .product-title {
        font-weight: bold;
        text-transform: uppercase; }
        @media (max-width: 800px) {
          .product-page__product__description .product-title {
            display: none; } }
    .product-page__product__info {
      flex-grow: 1;
      position: relative; }
      @media (max-width: 540px) {
        .product-page__product__info {
          width: 100%; } }
      .product-page__product__info--fixed {
        top: 0;
        position: sticky;
        width: auto; }
    .product-page__product__gallery {
      flex-wrap: wrap;
      box-sizing: border-box; }
      .product-page__product__gallery__nav li {
        display: inline-block;
        padding: 0.5rem; }
        .product-page__product__gallery__nav li span {
          width: 0.5rem;
          height: 0.5rem;
          border: 1px solid #c4c4c4;
          border-radius: 0.5rem;
          display: block; }
        .product-page__product__gallery__nav li.active span {
          background-color: #c4c4c4;
          border-color: #c4c4c4; }
      .product-page__product__gallery .category-title {
        font-family: "DIN Pro";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        padding: 1rem 0;
        text-transform: uppercase;
        display: none;
        text-align: center; }
        @media (max-width: 800px) {
          .product-page__product__gallery .category-title {
            display: block; } }
      .product-page__product__gallery .product-title {
        text-transform: uppercase;
        display: none;
        text-align: center;
        font-size: 56px;
        font-weight: bold; }
        @media (max-width: 800px) {
          .product-page__product__gallery .product-title {
            display: block; } }
  .product-page__other-products {
    background-color: #fff;
    padding: 4rem 0; }
    .product-page__other-products__title {
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 2rem; }
      @media (max-width: 540px) {
        .product-page__other-products__title {
          text-align: center; } }
  .product-page .button__download2 {
    display: none; }
    @media (max-width: 540px) {
      .product-page .button__download2 {
        margin-top: 180px;
        display: block;
        background-color: #0A2057; } }
