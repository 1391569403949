:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.modal {
  padding: 0 2rem 0; }
  .modal__title {
    margin-bottom: 2rem; }
  .modal__footer {
    margin-top: 2rem;
    position: relative; }
    .modal__footer:before {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background: #c4c4c4;
      height: 1px;
      width: calc(100% + 2rem * 2); }
  .modal__cancelBtn {
    font-size: 16px;
    color: #21125e;
    background: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    transform: none;
    text-decoration: underline;
    padding: 0;
    margin-right: 1rem; }
    .modal__cancelBtn:hover {
      background: transparent; }
