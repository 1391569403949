:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.breadcrumbs {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 1rem 0; }
  @media (max-width: 540px) {
    .breadcrumbs {
      font-size: 12px;
      display: flex;
      justify-content: center; } }
  .breadcrumbs li:not(:last-of-type):after {
    content: url("../../../images/breadcrumbs-arrow.svg");
    display: inline-block;
    margin: 0 0.5rem; }
  .breadcrumbs li a {
    color: #7d7d7d;
    text-decoration: none;
    vertical-align: middle;
    transition: 0.3s; }
    .breadcrumbs li a:hover, .breadcrumbs li a:focus {
      color: #000; }
  .breadcrumbs__active a {
    color: #323232;
    font-weight: 600; }
    .breadcrumbs__active a:hover {
      color: #323232; }
