:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.terms-conditions-container {
  margin: 4rem auto; }
  .terms-conditions-container b {
    font-weight: bold;
    margin: 1rem 0;
    display: inline-block; }
  .terms-conditions-container strong {
    font-weight: bold; }
  .terms-conditions-container p {
    margin: 0.5rem 0;
    display: inline-block; }
  .terms-conditions-container .mt-1 {
    margin-top: 1rem; }
  .terms-conditions-container .center {
    display: flex;
    justify-content: center; }
