:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.article-page__container-breadcumbs {
  display: flex;
  justify-content: center; }
  .article-page__container-breadcumbs .breadcrumbs {
    width: 90%; }

.article-page__container {
  color: white;
  background-color: black;
  display: flex;
  margin: 60px 0;
  justify-content: center;
  padding: 3rem 0; }
  @media (max-width: 540px) {
    .article-page__container {
      flex-direction: column;
      align-items: center; } }

.article-page__header {
  text-align: center;
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
  background-size: cover;
  background-color: #c4c4c4;
  background-blend-mode: multiply; }
  .article-page__header__title {
    background-color: #fff;
    display: inline-block;
    transform: skew(45deg);
    padding: 1.5vw 9vw; }
    .article-page__header__title h1 {
      display: inline-block;
      transform: skew(-45deg);
      font-weight: 600;
      text-transform: uppercase;
      color: #c4c4c4;
      font-size: 8vw;
      font-weight: 900; }
      @media (min-width: 540px) {
        .article-page__header__title h1 {
          font-size: 4vw; } }

.article-page__content {
  width: 60%; }
  @media (max-width: 540px) {
    .article-page__content {
      width: 90%; } }
  .article-page__content h1,
  .article-page__content h2,
  .article-page__content h3 {
    font-weight: 600; }
  .article-page__content h2 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem; }
  .article-page__content h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem; }
  .article-page__content img {
    max-width: 100%; }
  .article-page__content a {
    color: white;
    font-weight: 600; }
  .article-page__content strong {
    font-weight: 600; }
  .article-page__content p {
    padding-top: 1rem; }
  .article-page__content ol {
    list-style: inside;
    list-style-type: decimal;
    padding-top: 1rem; }

.article-page__navigation {
  width: 30%; }
  @media (max-width: 992px) {
    .article-page__navigation {
      margin-bottom: 2rem; } }
  @media (max-width: 540px) {
    .article-page__navigation {
      width: 90%; } }
  .article-page__navigation__title {
    font-size: 1.2rem;
    padding-bottom: 1rem; }
  .article-page__navigation-element {
    font-family: "Montserrat";
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: uppercase; }
    .article-page__navigation-element--active a {
      display: inline-block;
      position: relative; }
      .article-page__navigation-element--active a:before {
        background-color: #000;
        content: "";
        height: 7px;
        left: 0;
        position: absolute;
        top: 6px;
        transform: skew(45deg);
        width: 100%;
        z-index: -1; }
