:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.login-form .input {
  margin-bottom: 1rem; }

.login-form__button {
  margin-top: 2rem;
  padding: 0 1.5rem;
  text-align: center; }
