:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

body, html {
  overflow-x: hidden;
  position: relative; }

.main-menu {
  align-items: center;
  align-items: stretch;
  justify-content: space-between;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  height: 100%;
  position: relative;
  padding-top: 24px;
  margin: 0 auto;
  padding-bottom: 16px; }
  @media (max-width: 540px) {
    .main-menu {
      padding-top: 1rem;
      margin-bottom: 2rem;
      height: auto; } }
  .main-menu__space {
    width: 100vw;
    max-width: 1222px;
    height: 8rem; }
    @media (max-width: 540px) {
      .main-menu__space {
        height: 6rem; } }
  .main-menu__container {
    background-color: #fff;
    width: 100%;
    height: 8rem;
    position: fixed;
    z-index: 999990;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2); }
    @media (max-width: 540px) {
      .main-menu__container {
        height: 6rem; } }
  .main-menu__left {
    align-self: center;
    flex-basis: calc(25%); }
    @media (max-width: 1200px) {
      .main-menu__left {
        flex-basis: calc(20%); } }
    @media (max-width: 992px) {
      .main-menu__left {
        flex-basis: calc(15% - 2rem); } }
    .main-menu__left .logo {
      width: 190px; }
      @media (max-width: 992px) {
        .main-menu__left .logo {
          width: 113px;
          height: 64px; } }
    .main-menu__left svg {
      width: 10rem; }
      @media (min-width: 540px) {
        .main-menu__left svg {
          width: 10rem; } }
  .main-menu__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 15px; }
    @media (max-width: 992px) {
      .main-menu__right {
        justify-content: center; } }
    .main-menu__right ul {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 18px;
      -webkit-justify-content: flex-end;
      -webkit-align-items: center;
      -webkit-gap: 18px; }
      .main-menu__right ul.socialMedia {
        gap: 42px;
        -webkit-gap: 42px; }
        @media (max-width: 992px) {
          .main-menu__right ul.socialMedia {
            display: none; } }
  .main-menu__center {
    flex-basis: calc(40%); }
    @media (max-width: 1200px) {
      .main-menu__center {
        flex-basis: calc(50%); } }
    @media (max-width: 992px) {
      .main-menu__center {
        flex-basis: calc(60% - 4rem);
        font-size: 13px; } }
    @media (max-width: 540px) {
      .main-menu__center {
        flex-basis: calc(30% - 6rem); } }
    .main-menu__center ul {
      justify-content: center;
      gap: 61px;
      display: flex;
      align-items: center;
      height: 100%;
      text-transform: uppercase;
      font-size: 14px;
      -webkit-justify-content: center;
      -webkit-gap: 61px;
      -webkit-align-items: center;
      -webkit-height: 100%;
      -webkit-text-transform: uppercase;
      -webkit-font-size: 14px; }
      .main-menu__center ul li a {
        color: #0A2057; }
        .main-menu__center ul li a:hover {
          font-weight: bold;
          padding-bottom: 7px;
          border-bottom: 3px solid #F05C66; }
      @media (max-width: 750px) {
        .main-menu__center ul {
          display: none; } }
  .main-menu__item {
    font-weight: 600;
    height: 100%;
    text-transform: uppercase; }
    .main-menu__item a {
      text-decoration: none;
      color: #f6f6f6;
      position: relative;
      transition: 0.3s; }
      .main-menu__item a:hover {
        color: black; }
  .main-menu__sidemenu-hamburger {
    font-size: 30px;
    cursor: pointer;
    margin-top: 10px; }
  .main-menu__hamburger {
    border-right: 1px solid #f6f6f6;
    cursor: pointer;
    padding: 1rem; }
    .main-menu__hamburger svg {
      vertical-align: bottom; }
  .main-menu__item, .main-menu__hamburger {
    align-items: center;
    flex-direction: column;
    justify-content: center; }
  .main-menu__icon {
    cursor: pointer; }
    .main-menu__icon svg * {
      transition: 0.3s;
      fill: #c4c4c4; }
    .main-menu__icon:hover svg * {
      fill: #0A2057; }
    .main-menu__icon svg {
      vertical-align: bottom;
      width: 24px;
      height: 24px; }
  .main-menu__cart {
    position: relative; }
    .main-menu__cart__quantity {
      position: absolute;
      top: -7px;
      right: 22px;
      background-color: #0A2057;
      border: 1px solid white;
      border-radius: 50%;
      min-width: 0.8rem;
      height: 0.8rem;
      color: #fff;
      font-size: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s; }
  .main-menu__dropdown {
    text-transform: uppercase;
    font-weight: 600; }
    .main-menu__dropdown li {
      cursor: pointer;
      margin: 0 auto; }
      .main-menu__dropdown li:hover {
        color: #000; }
      .main-menu__dropdown li:not(:last-of-type) {
        border-bottom: 1px solid #f6f6f6; }
  .main-menu__offline {
    line-height: 24px;
    padding: 1rem; }
    .main-menu__offline span {
      text-transform: uppercase;
      font-weight: 600;
      vertical-align: bottom; }
  .main-menu__search {
    padding: 1rem;
    cursor: pointer;
    line-height: 24px;
    transition: 0.3s;
    display: none; }
    @media (min-width: 992px) {
      .main-menu__search {
        border-left: 1px solid #f6f6f6; } }
    .main-menu__search svg * {
      transition: 0.3s;
      fill: #c4c4c4; }
    .main-menu__search:hover {
      color: #0A2057; }
      .main-menu__search:hover svg * {
        fill: #0A2057; }
    .main-menu__search span {
      text-transform: uppercase;
      font-weight: 600;
      margin-right: 1rem; }
    .main-menu__search div {
      display: inline-block; }
    .main-menu__search svg {
      vertical-align: bottom; }
  .main-menu__submenu {
    display: none; }
    @media (max-width: 540px) {
      .main-menu__submenu {
        display: block; }
        .main-menu__submenu .submenu {
          height: 30px;
          width: 30px;
          background-repeat: no-repeat;
          background-image: url("../../../images/submenu.png"); } }
  .main-menu__nav-dropdown {
    padding: 0 1.5rem; }
    .main-menu__nav-dropdown--active {
      background-color: #f6f6f6; }
    .main-menu__nav-dropdown > li:first-child {
      text-align: center; }
    .main-menu__nav-dropdown__body {
      background: #fff;
      border-top-color: #f6f6f6;
      border-top-style: solid;
      border-top-width: 1px;
      display: none;
      left: 0;
      padding: 2rem;
      position: absolute;
      top: 100%;
      width: 100%;
      z-index: 3; }
      .main-menu__nav-dropdown__body--visible {
        display: block; }
      .main-menu__nav-dropdown__body li *,
      .main-menu__nav-dropdown__body ul * {
        display: block !important; }
      .main-menu__nav-dropdown__body > ul > li {
        align-self: flex-start;
        margin-left: 2rem;
        position: relative;
        width: 188px; }
        .main-menu__nav-dropdown__body > ul > li > a {
          font-size: 1rem;
          position: relative;
          text-transform: uppercase;
          padding-bottom: 9px;
          margin-bottom: 3px; }
        .main-menu__nav-dropdown__body > ul > li > ul > li:first-child {
          position: relative; }
          .main-menu__nav-dropdown__body > ul > li > ul > li:first-child:before {
            background-color: #f6f6f6;
            content: "";
            height: 1px;
            left: 0;
            position: absolute;
            top: -3px;
            width: 100%; }
        .main-menu__nav-dropdown__body > ul > li ul a {
          font-size: 0.875rem;
          font-weight: normal;
          padding: 6px 0;
          text-transform: capitalize; }
        .main-menu__nav-dropdown__body > ul > li ul li > ul {
          margin-left: 10px; }

.phone-menu {
  display: none; }
  @media (max-width: 540px) {
    .phone-menu {
      display: flex;
      justify-content: center;
      padding-bottom: 2.5rem;
      flex-wrap: wrap;
      margin-top: 30px; }
      .phone-menu .link {
        padding: 0 1rem;
        color: #0A2057;
        font-size: 11px;
        font-weight: 400px;
        height: 41px;
        text-transform: uppercase;
        border-right-color: #bdbdbd;
        border-right-style: solid;
        border-right-width: 1px;
        display: flex;
        align-items: center; }
        .phone-menu .link:last-of-type {
          border: none; } }

.mobile-menu {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: #0A2057;
  z-index: 1000000;
  padding: 8px 32px 16px 40px;
  overflow-y: scroll;
  transform: translateX(110%); }
  .mobile-menu__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
  .mobile-menu__logo {
    width: 121px;
    height: 75.99px;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(214deg) brightness(99%) contrast(103%); }
  .mobile-menu__close {
    cursor: pointer; }
  .mobile-menu__about {
    padding-top: 16px;
    color: #fff; }
    .mobile-menu__about--title {
      color: inherit;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 20px;
      cursor: pointer; }
    .mobile-menu__about ul {
      color: inherit;
      margin: 24px 0px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      text-align: left;
      text-transform: uppercase;
      font-size: 12px;
      animation: show 0.5s linear forwards; }
    .mobile-menu__about ul a {
      color: inherit;
      cursor: pointer;
      line-height: 14px; }
  .mobile-menu__line {
    width: 100%;
    height: 1px;
    background-color: #06153d;
    margin: 8px 0; }
  .mobile-menu__expand {
    margin-left: 4px;
    height: 7px;
    transform: rotate(-90deg);
    transition: transform 0.1s linear; }
    .mobile-menu__expand--rotate {
      margin-left: 4px;
      height: 7px;
      transform: rotate(0deg);
      transition: transform 0.1s linear; }
  .mobile-menu__center {
    display: flex;
    align-items: center; }
  .mobile-menu__lngoption {
    cursor: pointer;
    padding-right: 4px; }
    .mobile-menu__lngoption--active {
      font-weight: bold;
      text-decoration: underline; }
  .mobile-menu__language {
    color: #EE2533;
    font-size: 9px;
    line-height: 11px;
    margin-top: 32px;
    margin-bottom: 8px; }
    .mobile-menu__language--separator {
      margin-right: 4px; }
    .mobile-menu__language--container {
      display: flex;
      justify-content: space-between;
      align-items: center; }
  .mobile-menu__languages {
    font-size: 11px;
    display: flex;
    color: #fff; }
  .mobile-menu__socialMedia {
    display: flex;
    width: 60%;
    justify-content: flex-end; }
    .mobile-menu__socialMedia ul {
      width: 100%;
      display: flex;
      justify-content: space-between;
      max-width: 248px; }
    .mobile-menu__socialMedia--icon {
      width: 22px;
      height: 18px; }
  .mobile-menu__terms {
    display: flex;
    align-items: center;
    margin: 16px 0px;
    font-size: 9px;
    color: white;
    line-height: 11px; }
    .mobile-menu__terms a {
      color: white;
      font-size: 9px;
      line-height: 11px;
      margin-left: 16px; }
  .mobile-menu__copyright {
    color: white;
    font-size: 12px;
    line-height: 14.89px; }
  .mobile-menu--open {
    transition: transform 0.3s linear;
    transform: translateX(0%);
    -webkit-transform: translateX(0%); }
  .mobile-menu--close {
    transition: transform 0.3s linear;
    transform: translateX(110%);
    -webkit-transform: translateX(110%); }

@keyframes show {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
