:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.carousel__control {
  padding: 0.2rem 0.5rem;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25); }
  .carousel__control:hover {
    cursor: pointer; }
  .carousel__control--right {
    margin-right: -1.3rem; }
    @media (max-width: 1140px) {
      .carousel__control--right {
        margin-right: 0; } }
    .carousel__control--right svg {
      transform: rotate(180deg);
      margin-top: 0.5rem; }
  .carousel__control--left {
    margin-left: -1.3rem; }
    @media (max-width: 1140px) {
      .carousel__control--left {
        margin-left: 0; } }
    .carousel__control--left svg {
      margin-top: 0.4rem; }

.carousel .slider-slide {
  text-align: center; }
