:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

@keyframes sidenavLeftOpen {
  from {
    left: -100rem; }
  to {
    left: 0; } }

@keyframes sidenavRightOpen {
  from {
    right: -100rem; }
  to {
    right: 0; } }

.overlay {
  height: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  transition: 300ms;
  width: 100%;
  z-index: 2000000;
  background-color: rgba(0, 0, 0, 0.5); }
  .overlay--login, .overlay--cart {
    top: 8rem;
    height: calc(100% - 8rem); }
    @media (max-width: 540px) {
      .overlay--login, .overlay--cart {
        top: 6rem; } }
    .overlay--login .overlay__right, .overlay--cart .overlay__right {
      height: calc(100% - 8rem);
      top: 8rem; }
      @media (max-width: 540px) {
        .overlay--login .overlay__right, .overlay--cart .overlay__right {
          top: 6rem; } }
  .overlay--search, .overlay--main-menu-nav {
    top: 3.55rem; }
  .overlay--search .overlay__right {
    background-color: transparent;
    height: auto; }
  .overlay--no-background {
    background-color: transparent; }
  .overlay__left {
    position: fixed;
    min-height: 100%;
    background-color: #fff;
    animation: sidenavLeftOpen 0.3s; }
  .overlay__right {
    position: fixed;
    background-color: #fff;
    right: 0;
    top: 0;
    height: 100%;
    animation: sidenavRightOpen 0.3s; }
  .overlay__header {
    align-items: center;
    border-bottom: 1px solid #f6f6f6;
    display: flex;
    flex: 1;
    font-weight: 600;
    height: 3.6rem;
    justify-content: space-between;
    text-transform: uppercase; }
    .overlay__header-text {
      margin: 0 1rem;
      text-align: center; }
      .overlay__header-text-items {
        color: #c4c4c4; }
    .overlay__header__close-icon {
      cursor: pointer;
      margin: 0 1rem; }
      .overlay__header__close-icon:hover svg *, .overlay__header__close-icon:focus svg * {
        fill: #000; }
      .overlay__header__close-icon div {
        display: flex; }
    .overlay__header__cart-icon {
      margin: 0 1rem; }
      .overlay__header__cart-icon div {
        display: flex; }
        .overlay__header__cart-icon div svg * {
          fill: #000; }
