:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.address-summary {
  color: #7d7d7d;
  line-height: 1.5;
  font-size: 0.875rem; }
  .address-summary strong {
    font-weight: 600;
    display: inline-block;
    padding-bottom: 0.5rem; }
