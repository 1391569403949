:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.checkout-login {
  margin: 10rem 0;
  display: flex; }
  @media (max-width: 992px) {
    .checkout-login {
      display: block;
      margin: 2rem 0; } }
  .checkout-login h3 {
    margin-bottom: 1rem; }
  .checkout-login__guest {
    width: 50%;
    padding: 0 4rem; }
    @media (max-width: 992px) {
      .checkout-login__guest {
        width: 60%;
        margin: 0 auto 4rem;
        padding: 0 1rem; } }
    @media (max-width: 540px) {
      .checkout-login__guest {
        width: 100%;
        padding: 0 0 4rem; } }
    .checkout-login__guest p {
      color: #7d7d7d; }
    .checkout-login__guest button {
      margin: 2rem 0 2rem 2rem; }
  .checkout-login__user {
    width: 50%;
    padding: 0 4rem; }
    @media (max-width: 992px) {
      .checkout-login__user {
        width: 60%;
        margin: 0 auto;
        padding: 0 1rem; } }
    @media (max-width: 540px) {
      .checkout-login__user {
        width: 100%;
        padding: 0; } }
    .checkout-login__user .input {
      margin-bottom: 1rem; }
  .checkout-login .u-link {
    color: black; }
    .checkout-login .u-link:hover {
      color: gray; }
