:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.privacy-policies-container {
  margin: 4rem auto; }
  .privacy-policies-container b {
    font-weight: bold;
    margin: 1rem 0;
    display: inline-block; }
  .privacy-policies-container strong {
    font-weight: bold; }
  .privacy-policies-container p {
    margin: 0.5rem 0;
    display: inline-block; }
  .privacy-policies-container .mt-1 {
    margin-top: 1rem; }
  .privacy-policies-container .center {
    display: flex;
    justify-content: center; }
  .privacy-policies-container .list {
    margin-left: 100px; }
